const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
// const Dropbox = require('@uppy/dropbox')
// const OneDrive = require('@uppy/onedrive')
// const GoogleDrive = require('@uppy/google-drive')
// const Url = require('@uppy/url')
const AwsS3 = require('@uppy/aws-s3')

require("@uppy/core/dist/style.css")
require("@uppy/dashboard/dist/style.css")

const DOC_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const CLEAN_COPIABLE_MIME_TYPES = DOC_MIME_TYPES

const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/gif',
  'image/png'
]

const PDF_MIME_TYPES = [
  'application/pdf'
]

const MAX_FILE_SIZE = 200 * 1024 * 1024  // Maximum 200MB

document.addEventListener('turbolinks:load', () => {
  [...document.querySelectorAll('[data-uppy]')].forEach(element => setupUppy(element))
})

function setupUppy(element) {
  let trigger = element.querySelector('[data-behavior="uppy-trigger"]:not(.disabled)')
  if (!trigger) {
    return
  }

  let limit = parseInt(trigger.dataset.limit) || null
  let field_name = element.dataset.uppy
  let fileTypes = element.dataset.uppyFormat
  let allowedFileCategory = 'document'
  const uppyScope = element.dataset.scope

  if (fileTypes === undefined || fileTypes == 'documents') {
    fileTypes = [...DOC_MIME_TYPES, ...PDF_MIME_TYPES, '.doc', '.docx', '.pdf']
  } else {
    fileTypes = [...IMAGE_MIME_TYPES, '.png', '.jpeg', '.jpg', '.gif'];
    allowedFileCategory = 'image'
  }

  trigger.addEventListener("click", (event) => event.preventDefault())

  let uppy = Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      allowedFileTypes: fileTypes,
      maxNumberOfFiles: limit
    },
    onBeforeUpload: (files) => {
      let fileLarge = false
      Object.keys(files).forEach(fileID => {
        let file = files[fileID]
        if (file.size > MAX_FILE_SIZE) {
          fileLarge = true
        }
      })
      if (fileLarge) {
        let msg = 'Your file is too large. There are two solutions. ' +
          'Upload a Word document containing a link to your large file, ' +
          'such as a link to a file stored in Dropbox, or remove whatever is ' +
          'dramatically increasing the file size, such as images, and try to ' +
          'upload the file again.'
        showAlertModal('Error', msg)

        return {}
      }
      return files
    }
  })

  // Hack of uppy to replace restricted file types message
  if (allowedFileCategory === 'document') {
    const documentFileTypeAlert = 'Thanks for the link! We only accept Word files and PDFs. Do you have one of those?'
    uppy.translator.locale.strings.youCanOnlyUploadFileTypes = documentFileTypeAlert
    uppy.locale.strings.youCanOnlyUploadFileTypes = documentFileTypeAlert
  }

  uppy.use(Dashboard, {
    trigger: trigger,
    closeAfterFinish: true,
  })

  // uppy.use(Dropbox, {
  //   target: Dashboard,
  //   companionUrl: process.env.COMPANION_URL
  // })

  // uppy.use(OneDrive, {
  //   target: Dashboard,
  //   companionUrl: process.env.COMPANION_URL
  // })

  // uppy.use(GoogleDrive, {
  //   target: Dashboard,
  //   companionUrl: process.env.COMPANION_URL
  // })

  // uppy.use(Url, {
  //   target: Dashboard,
  //   companionUrl: process.env.COMPANION_URL
  // })

  uppy.use(AwsS3, {
    limit: 1,
    timeout: 3600000,
    companionUrl: process.env.COMPANION_URL
  })

  uppy.on('complete', (result) => {
    uppy.reset()

    if (uppyScope !== 'admin') {
      alertPDFUpload(element, result.successful)
    }

    result.successful.forEach(file => {
      appendUploadedFile(element, file, field_name)
      setPreview(element, file)
    })

    checkFileTypes(element)
  })

  setRemoveFileButtonListeners(element)
}

function appendUploadedFile(element, file, field_name) {
  let fileObj = {
    key_name: file.response.body.key,
    filename: file.name,
    content_type: file.type,
    byte_size: file.size,
    etag: file.response.body.etag
  }

  let hiddenField = document.createElement('input')
  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', JSON.stringify(fileObj))
  hiddenField.setAttribute('data-file-key', file.response.body.key)
  hiddenField.setAttribute('data-file-type', file.type)
  element.appendChild(hiddenField)
}

function removeUploadedFile(element, file_key) {
  if (!element || !file_key) {
    return
  }

  let previewFileList = element.querySelector('[data-behavior="uppy-preview-file-list"]')
  if (previewFileList) {
    let listItem = previewFileList.querySelector('a.uppy-remove-file[data-file-key="' + file_key + '"]').closest('li')
    listItem.parentNode.removeChild(listItem)
  }

  let fieldElement = element.querySelector('input[type="hidden"][data-pending-upload][data-file-key="' + file_key + '"]')
  if (fieldElement) {
    fieldElement.parentNode.removeChild(fieldElement)
  }

  checkFileTypes(element)
}

function setPreview(element, file) {
  let preview = element.querySelector('[data-behavior="uppy-preview-image"]')

  if (preview && file.preview) {
    preview.src = file.preview
  }

  let previewFileList = element.querySelector('[data-behavior="uppy-preview-file-list"]')

  if (previewFileList) {
    listItemHTML = previewFileList.innerHTML
    listItemHTML +=
      '<li>' +
      file.name +
      '<a class="uppy-remove-file" data-file-key="' + file.response.body.key + '" style="margin-left: 10px; cursor: pointer;">' +
      '<i class="fas fa-times" style="color: red;" />' +
      '</a>' +
      '</li>'
    previewFileList.innerHTML = listItemHTML

    setRemoveFileButtonListeners(element);
  }
}

function setRemoveFileButtonListeners(element) {
  let previewFileList = element.querySelector('[data-behavior="uppy-preview-file-list"]')

  if (previewFileList) {
    let removeButtons = previewFileList.querySelectorAll('.uppy-remove-file')
    removeButtons.forEach(function (removeButton) {
      removeButton.onclick = function (e) {
        e.preventDefault()

        buttonLink = e.target.closest('.uppy-remove-file')
        if (buttonLink) {
          let fileKey = buttonLink.getAttribute('data-file-key')
          removeUploadedFile(element, fileKey)
        }
      }
    })
  }
}

function checkFileTypes(element) {
  if (!element) {
    return
  }

  // hook for the like clean copy group
  if ($('#order_can_clean_copy').length <= 0) {
    return
  }

  let canCleanCopy = false

  let fieldElements = element.querySelectorAll('input[type="hidden"][data-pending-upload]')
  Array.from(fieldElements).forEach(function (field) {
    const fileType = field.getAttribute('data-file-type')
    if (CLEAN_COPIABLE_MIME_TYPES.includes(fileType)) {
      canCleanCopy = true
    }
  })

  if (canCleanCopy) {
    $('#order_can_clean_copy').val('yes')
    $('#order_clean_copy_group').show()
  } else {
    $('#order_can_clean_copy').val('')
    $('#order_clean_copy_group').hide()
    $('#order_like_clean_copy_false').prop('checked', true)
  }
}

function alertPDFUpload(element, newFiles) {
  if (!element || !newFiles) {
    return
  }

  // alert only on source files
  const fieldName = element.getAttribute('data-uppy')
  if (fieldName != 'order[source_files][]') {
    return
  }

  let hasPDF = false

  newFiles.forEach(file => {
    if (PDF_MIME_TYPES.includes(file.type)) {
      hasPDF = true
    }
  })

  let alreadyHasPDF = false

  let fieldElements = element.querySelectorAll('input[type="hidden"][data-pending-upload]')
  Array.from(fieldElements).forEach(function (field) {
    const fileType = field.getAttribute('data-file-type')
    if (PDF_MIME_TYPES.includes(fileType)) {
      alreadyHasPDF = true
    }
  })

  if (hasPDF && !alreadyHasPDF) {
    const msg = `If possible, consider uploading a Word document instead of a PDF. <br/><br/><ul><li>Word documents allow you to reject or accept our changes with just a few clicks.</li><li>PDFs require you to manually enter all our changes.</li></ul>While we're happy to make annotations to your PDF file, we want to make things as easy as possible for you.`

    showAlertModal('Notice', msg)
  }
}
